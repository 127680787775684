import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import IsGTMScriptLoaded from '../../../../../utils/isGTMScriptLoaded';
import getSymbolFromCurrency from 'currency-symbol-map';
import { selectSelectedCurrency } from '../../../../../store/user';
import { selectProducts } from '../../../../../store/products';
import ProductCard from './productCard';
import { ProductBoxCart } from '../../../../../pages/home/components/productBoxCart';
import useCart from '../../../../../hooks/cart/useCart';
import MultiProductsBoxCart from '../../../../general/multiProductsBoxCart';
import sortProductsByCurrency from '../../../../../utils/productSortHandler';
import { checkDomain } from '../../../../../utils/checkDomain';
const ProductBoxesV3 = ({ products = [], sortByCurrency = false, videos = [], usaProducts = [], ukProducts = [] }) => {
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState(selectedCurrencyStore || 'AUD');
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const _allProducts = useSelector(state => selectProducts(state));
  const handleIsAnyAmount = product => {
    const hasQuantity = product.quantity ? product.quantity > 1 : false;
    return Number(product.aud) <= 2 && !hasQuantity;
  };
  const [selectedVideos, setSelectedVideos] = useState([]);
  const { handleCheckProduct, handleAddToGiving, handleRemoveProduct } = useCart();
  const handleViewItemDataLayer = (tempProduct, currency) => {
    const dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct[currency.toLowerCase()],
          },
        ],
      },
    };
    window.dataLayer = window.dataLayer || [];
    if (IsGTMScriptLoaded()) {
      window.dataLayer.push(dataLayerTemp);
    }
    const obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category || 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description,
    };
    if (window.ttq) {
      if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org') || checkDomain('matwproject.org.uk'))) {
        window.ttq.instance(localStorage.getItem('TikTokID')).track('ViewContent', obj);
      }
    }
  };
  const ShowCurrencies = (currency, _price) => {
    const tempCurrency = currency?.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let final = '';
    price = price ? price.toLocaleString() : '0';
    switch (tempCurrency) {
      case 'GBP':
      case 'EUR':
        final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        final = `${price}`;
        break;
      case 'IDR':
        final = `Rp${price}`;
        break;
      case 'AED':
        final = `Dh${price}`;
        break;
      default:
        final = `$${price}`;
        break;
    }
    return final;
  };
  const getSelectedProducts = _allProducts => {
    setIsLoaded(true);
    const tempSelectedProducts = [];
    let tempVideos = [];

    // Convert products, usaProducts, and ukProducts from strings to arrays
    const productsArray = typeof products === 'string' ? products.split(',') : products;
    const usaProductsArray = typeof usaProducts === 'string' ? usaProducts.split(',') : usaProducts;
    const ukProductsArray = typeof ukProducts === 'string' ? ukProducts.split(',') : ukProducts;

    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          if (productsArray.includes(innerProduct.creator)) {
            tempSelectedProducts.push(innerProduct);
          }
          if (checkDomain('matwprojectusa.org') && usaProductsArray.includes(innerProduct.creator)) {
            tempSelectedProducts.push(innerProduct);
          }
          if (checkDomain('matwproject.org.uk') && ukProductsArray.includes(innerProduct.creator)) {
            tempSelectedProducts.push(innerProduct);
          }
        });
      });
    });

    // Combine all product lists into one array for sorting
    const combinedProducts = [...productsArray, ...usaProductsArray, ...ukProductsArray];

    // Sort products based on their position in the combinedProducts array
    const sortedByCreator = tempSelectedProducts.sort((a, b) => {
      return combinedProducts.indexOf(a.creator) - combinedProducts.indexOf(b.creator);
    });

    const uniqueProducts = sortedByCreator.reduce((acc, current) => {
      const existingProduct = acc.find(item => item.creator === current.creator);
      if (!existingProduct) {
        acc.push(current);
      }
      return acc;
    }, []);

    if (videos.length > 0) {
      tempVideos = videos.split(',').map(video => ({
        video_src: video.trim(),
      }));
    }

    setSelectedProducts(uniqueProducts);
    setSelectedVideos(tempVideos);
    console.log('tempVideos', tempVideos);
  };
  useEffect(() => {
    if (products.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getSelectedProducts(_allProducts);
    }
  }, [_allProducts, products]);

  useEffect(() => {
    setSelectedCurrency(selectedCurrencyStore || 'AUD');
  }, [selectedCurrencyStore]);

  const sortedProducts = sortByCurrency
    ? sortProductsByCurrency([...selectedProducts], selectedCurrency.toLowerCase())
    : [...selectedProducts];
  return (
    <div className="flex flex-wrap gap-3 md:gap-4 justify-center items-start w-full">
      {isLoaded ? (
        sortedProducts.length > 0 ? (
          sortedProducts.map((product, index) => (
            <>
              <div key={`product-${product.creator}-${index}`} className="w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)] hidden sm:block">
                <ProductCard
                  product={product}
                  alt={''}
                  buttonLabel={''}
                  _lang={'en'}
                  removeDataLayer={false}
                  isVisibleDescription={true}
                  roundedButton={false}
                  squareButton={false}
                  selectedCurrencyStore={selectedCurrencyStore}
                  handleViewItemDataLayer={handleViewItemDataLayer}
                  handleIsAnyAmount={handleIsAnyAmount}
                  ShowCurrencies={ShowCurrencies}
                />
              </div>
              <div className="w-full sm:hidden">
                {product.products ? (
                  <MultiProductsBoxCart
                    key={`product-${product.creator}-${index}`}
                    product={product}
                    multiProducts={product.products}
                    staticLink={'/'}
                    redirectUrl={product.redirectUrl}
                    title={product.title}
                    description={product.description}
                    image={product.img}
                    _lang={'eng'}
                    alt={product?.alt}
                    currency={selectedCurrencyStore}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                  />
                ) : (
                  <ProductBoxCart
                    key={`product-${product.creator}-${index}`}
                    product={product}
                    onCheck={handleCheckProduct}
                    onAdd={handleAddToGiving}
                    onRemove={handleRemoveProduct}
                    currency={selectedCurrencyStore}
                    stateVariableObj={{ productName: product.name }}
                    _lang={'eng'}
                    linkBtnClasses={product.linkBtnClasses || ''}
                    isStaticProduct={['Zakat Calculator'].includes(product.product_type)}
                    staticLink={product.product_type ? product.short_description : ''}
                    staticLinkText={product.product_type ? product.name : ''}
                  />
                )}
              </div>
            </>
          ))
        ) : (
          <p className="text-center w-full">No products selected.</p>
        )
      ) : (
        <Skeleton count={4} />
      )}
      {isLoaded ? (
        selectedVideos.length > 0 ? (
          selectedVideos.map((item, index) => (
            <div className="w-full sm:max-w-[300px] sm:basis-[calc(50%-8px)] self-stretch">
              <div key={index} className={`border p-4 bg-white border-[#dddddd] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] h-full`}>
                <iframe
                  className="video"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={item.video_src}
                />
              </div>
            </div>
          ))
        ) : (
          ''
        )
      ) : (
        <Skeleton count={4} />
      )}
    </div>
  );
};
Builder.registerComponent(ProductBoxesV3, {
  name: 'ProductBoxesV3',
  inputs: [
    {
      name: 'products',
      type: 'string',
    },
    {
      name: 'sortByCurrency',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'videos',
      type: 'string',
    },
    {
      name: 'ukProducts',
      type: 'string',
    },
    {
      name: 'usaProducts',
      type: 'string',
    },
  ],
});
export default ProductBoxesV3;
