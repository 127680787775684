/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import services from '../../services';
import useScript from '../../utils/useScript';
import Confetti from 'react-confetti';
import HandleShowRecurring from '../../utils/showRecurringOnDataLayer';
import { sha256 } from 'js-sha256';
import { useSelector } from 'react-redux';
import ProductBox from '../../components/general/productBox';
import { selectSelectedCurrency } from '../../store/user';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';
import AddUniversalUSAScript from '../../utils/addUniversalUSAScript';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import TrackAdrollGlobalPixelUKScript from '../../utils/trackAdrollGlobalPixelUKScript';
// import AddAdrollGlobalPixelUKScript from '../../utils/addAdrollGlobalPixelUKScript';
import { BASKET_CACHE_KEY, HandleLocalStorage } from '../../utils/handleLocalStorage';
import { useTranslation } from 'react-i18next';
import { checkDomain } from '../../utils/checkDomain';
// import { deleteCookie, getCookie } from '../../utils/cookie';
// import { addToLocalStorageArray, isInLocalStorageArray } from '../../utils/localstorage';
import { Helmet } from 'react-helmet-new';
import androidAppLogo from '../../images/others/GetItOnGooglePlay_Badge_Web_color_English.png'
import iosAppLogo from '../../images/others/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import fetchPopupSettings from '../../utils/fetchPopSetting';

const SuccessPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const gateway = params.get('gateway');
  const CURRENCY = params.get('currency');
  const ID = params.get('session_id');
  const MODE = params.get('mode');
  const PayPalID = params.get('id');
  const isFuture = params.get('future_payment');
  const FlexibleID = params.get('flexible_id');
  const [modelDataLayer, setModelDataLayer] = useState();
  const aspects = [
    { currency: 'AUD', value: 1 },
    { currency: 'USD', value: 1.4679876 },
    { currency: 'CAD', value: 1.1148927 },
    { currency: 'EUR', value: 1.6346634 },
    { currency: 'GBP', value: 1.9074287 },
    { currency: 'SGD', value: 1.1098815 },
    { currency: 'MYR', value: 0.32472374 },
    { currency: 'AED', value: 2.36 },
    { currency: 'IDR', value: 9891.19 },
  ];
  const [ttqPayload, setTtqPayoad] = useState(false);
  const [eventPayload, setEventPayload] = useState({});
  const [donorEmail, setDonorEmail] = useState('');
  const [products, setProducts] = useState([0, 0]);
  // const [pixelID, setPixelID] = useState('')
  const { i18n } = useTranslation();
  const lang = i18n.language;
  // const [muslimAdFloodLight, setMuslimAdFloodLight] = useState('');
  const [dailyDonationInfo, setDailyDonationInfo] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [showAppleMeta, setShowAppleMeta] = useState(false);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  useScript(
    '',
    `	var a9 = new Object();
	a9.clientName="MATW%202.0";
	a9.clientID=800993;
	a9.eventType="conversion";
	a9.segmentNumber=2;
	a9.segmentName="Thank you";
	a9.kv={};
	a9.kv.donationtype=[""];       //Kindly pass values seprated by comma here
	a9.kv.donationvalue=[""];      //Kindly pass values seprated by comma here
	a9.kv.quantity=[""];          //Kindly pass values seprated by comma here
	a9.kv.paymentid=[""];        //Kindly pass values seprated by comma here`,
  );

  useScript(
    '',
    `var a9 = new Object();
	a9.clientName="MATW%202.0";
	a9.clientID=800993;
	a9.eventType="conversion";
	a9.segmentNumber=2;
	a9.segmentName="Thank you";`,
  );

  const handlePrint = () => {
    var query = window.location.search.substring(1);
    navigate(generateLinkWithLang(`/receipt?${query}`, lang));
    // navigate(`/receipt?${query}`);
  };
  const handleConvertToAud = (currency, value) => {
    let converted = 0;
    aspects
      .map(obj => obj)
      .filter(item => {
        if (item.currency === currency) converted = Number(value) * item.value;
        return null;
      });
    // console.log("converted", Number(converted).toFixed(2));
    return Number(converted).toFixed(2);
  };
  const handleStripeOrder = async order_id => {
    try {
      const response = await services.getStripeReceipt(order_id);
      handleClearUserBasket();
      const Data = response.data.checkout_session;
      customUseScript(
        '',
        `  (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
      tap('create', '44176-eecb19', { integration: "javascript" });
      tap('conversion', 'stripe-${Data.payment_intent}', '${parseFloat(Data.amount_total)}', {currency:'${Data.currency}'});`,
      );
      // const scriptContent = `gtagMuslimAdNetwork('event', 'purchase', {
      //   'allow_custom_scripts': true,
      //   'value': '${Data.amount_total}',
      //   'transaction_id': '${order_id}',
      //   'send_to': 'DC-13776505/saleik01/matwp0+transactions'
      //   });`;
      // customUseScript('', scriptContent);
      let noscriptContent =
        // eslint-disable-next-line no-template-curly-in-string
        '<img src="https://ad.doubleclick.net/ddm/activity/src=13776505;type=saleik01;cat=matwp0;qty=1;cost=[Revenue];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[OrderID]?" width="1" height="1" alt=""/>';
      noscriptContent = noscriptContent.replace('[Revenue]', Data.amount_total);
      noscriptContent = noscriptContent.replace('[OrderID]', order_id);
      useNoScript(noscriptContent);
      // `${parseFloat(Data.amount_total)}`, `{currency:${Data.currency}}`
      setDonorEmail(Data.user.email);
      let tempItems = Data.checkout_session_products.map(item => {
        return {
          item_id: item.stripe_price_point.product.creator,
          item_name: item.stripe_price_point.product.name,
          item_brand: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
          item_category: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
          item_description: item.stripe_price_point.product.description || '',
          price: item.stripe_price_point.unit_amount,
          quantity: item.quantity,
        };
      });
      let adroll_obj = {
        ORDER_ID: `${Data.payment_intent ? Data.payment_intent : order_id}`,
        USER_ID: `${Data.user.id}`,
        conversion_value: `${Data.amount_total}`,
        currency: `${Data.checkout_session_products[0].stripe_price_point.currency.name}`,
        products: Data.checkout_session_products.map(item => {
          return {
            price: item.stripe_price_point.unit_amount,
            product_id: item.stripe_price_point.product.creator,
            quantity: item.quantity,
          };
        }),
      };
      // Check if the AdRoll library is loaded
      if (typeof adroll !== 'undefined') {
        // Call the function with adroll_obj
        TrackAdrollGlobalPixelUKScript(adroll_obj);
      } else {
        // Wait for the AdRoll library to load
        window.adrollLoaded = function () {
          TrackAdrollGlobalPixelUKScript(adroll_obj);
        };
      }
      // let adrollProducts = Data.checkout_session_products.map(item => {
      //   return {
      //     price: item.stripe_price_point.unit_amount,
      //     product_id: item.stripe_price_point.product.creator,
      //     quantity: item.quantity,
      //   }
      // })
      let adminCost =
        Data.checkout_session_products[Data.checkout_session_products.length - 1].stripe_price_point.product.name === 'Admin Contribution'
          ? Number(Data.checkout_session_products[Data.checkout_session_products.length - 1].stripe_price_point.unit_amount)
          : 0;

      // let _user_phone = `+${Data.user.phone.replace(/^0+/, '')}`;
      let _user_phone = formatPhoneNumber(Data.user.phone);
      let dataLayerTemp = {
        event: 'purchase',
        value_aud: parseFloat(handleConvertToAud(Data.checkout_session_products[0].stripe_price_point.currency.name, Data.amount_total)),
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.user.stripe_customer_id ? Data.user.stripe_customer_id : Data.user.new_stripe_customer_id}`,
        frequency: HandleShowRecurring(Data.checkout_session_products[0].stripe_price_point.recurring),
        ecommerce: {
          transaction_id: Data.payment_intent ? Data.payment_intent : Data.id,
          shipping_tier: 'Stripe',
          payment_type: 'Google Pay',
          value: parseFloat(Data.amount_total),
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      let dataLayerOrderCompleted = {
        event: 'order_completed',
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.user.stripe_customer_id ? Data.user.stripe_customer_id : Data.user.new_stripe_customer_id}`,
        frequency: HandleShowRecurring(Data.checkout_session_products[0].stripe_price_point.recurring),
        ecommerce: {
          transaction_id: Data.payment_intent ? Data.payment_intent : Data.id,
          shipping_tier: 'Stripe',
          payment_type: 'Google Pay',
          value: parseFloat(Data.amount_total),
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      setModelDataLayer(dataLayerTemp);
      window.dataLayer = window.dataLayer || [];
      // console.log("datalayer=", dataLayerOrderCompleted, dataLayerTemp, IsGTMScriptLoaded())
      // IsGTMScriptLoaded() && window.dataLayer.push(dataLayerOrderCompleted);
      // IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
      function checkAndPushCustomEvent() {
        // console.log("checkAndPushCustomEvent=", dataLayerOrderCompleted, dataLayerTemp, checkGTMLoad())
        // if (checkGTMLoad()) {
        //   window.dataLayer.push(dataLayerOrderCompleted);
        //   window.dataLayer.push(dataLayerTemp);
        // } else {
        //   setTimeout(checkAndPushCustomEvent, 100);
        // }
        window.dataLayer.push(dataLayerOrderCompleted);
        window.dataLayer.push(dataLayerTemp);
        // 'transaction_id': '${order_id}',
        // let tempMuslimAd = getMuslimeAdId();
        // const scriptContent = `gtagMuslimAdNetwork('event', 'purchase', {
        //   'allow_custom_scripts': true,
        //   'value': '${Data.amount_total}',
        //   'transaction_id': '${Data.payment_intent ? Data.payment_intent : Data.id}',
        //   'send_to': ['DC-13776505-saleik01/matwp0+transactions','${tempMuslimAd}']
        //   });`;
        // customUseScript('', scriptContent);
      }
      checkAndPushCustomEvent();
      // console.log("currency", Data.checkout_session_products[0].stripe_price_point.currency.name, CURRENCY)
      if (CURRENCY !== Data.checkout_session_products[0].stripe_price_point.currency.name) {
        params.set('currency', Data.checkout_session_products[0].stripe_price_point.currency.name);
        // navigate(`/success?${params.toString()}`)
      }
      let tempContents2 = tempItems.map(item => {
        return {
          price: item.price,
          quantity: item.quantity,
          content_id: item.item_id,
          content_category: item.item_category,
          content_name: item.item_name,
          brand: item.item_brand,
        };
      });
      let obj2 = {
        content_type: 'product',
        contents: tempContents2,
        currency: Data.currency.toUpperCase(),
        value: parseFloat(Data.amount_total),
        description: '',
      };
      setTimeout(() => {
        // console.log("settime-", obj2)
        // window.ttq && window.ttq.instance(pixelID).track('Complete--Payment', {
        if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org') || checkDomain('matwproject.org.uk'))) {
          window.ttq &&
            window.ttq.track('CompletePayment', {
              //handleStripeOrder
              ...obj2,
            });
        }
        let _user_phone = formatPhoneNumber(Data.user.phone);
        window.ttq &&
          window.ttq.identify({
            email: Data.user.email,
            phone_number: _user_phone,
            external_id: `${Data.user.id}`,
          });
      }, 3000);
      setTtqPayoad(true);

      // let contentids = [];
      // tempItems.forEach(element => {
      //     contentids.push(element.item_id);
      // });
      // let obj = {
      //   contents: tempItems,
      //   currency: (Data.currency).toUpperCase(),
      //   value: Data.amount_total,
      //   price: Data.amount_total,
      //   content_id: contentids,
      //   email: Data.user.email,
      //   phone_number: `+${Data.user.phone}`,
      // }

      let tempContents = Data.checkout_session_products.map(item => {
        return {
          price: item.stripe_price_point.unit_amount,
          quantity: item.quantity,
          content_id: item.stripe_price_point.product.creator,
          content_category: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
          content_name: item.stripe_price_point.product.name || '',
          brand: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
        };
      });
      let obj = {
        content_type: 'product',
        contents: tempContents,
        currency: Data.currency.toUpperCase(),
        value: parseFloat(Data.amount_total),
        description: '',
        email: Data.user.email,
        phone_number: _user_phone,
        user_id: Data.user.id,
      };
      setEventPayload(obj);



    } catch (error) {
      console.error('error========', error);
    }
  };
  const handlePaypalOrder = async order_id => {
    // console.log("handlePaypalOrder", order_id);
    try {
      const response = await services.getPaypalOrderReceipt(order_id);
      handleClearUserBasket();
      // console.log('response getPaypalOrderReceipt', response);
      const Data = response.data;
      customUseScript(
        '',
        `  (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
      tap('create', '44176-eecb19', { integration: "javascript" });
      tap('conversion', 'paypal-${order_id}', '${parseFloat(Data.amount_total)}', {currency:'${Data.currency}'});`,
      );
      // const scriptContent = `gtagMuslimAdNetwork('event', 'purchase', {
      //   'allow_custom_scripts': true,
      //   'value': '${Data.amount_total}',
      //   'transaction_id': '${order_id}',
      //   'send_to': 'DC-13776505/saleik01/matwp0+transactions'
      //   });`;
      // customUseScript('', scriptContent);
      // eslint-disable-next-line no-template-curly-in-string
      let noscriptContent =
        '<img src="https://ad.doubleclick.net/ddm/activity/src=13776505;type=saleik01;cat=matwp0;qty=1;cost=[Revenue];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[OrderID]?" width="1" height="1" alt=""/>';
      noscriptContent = noscriptContent.replace('[Revenue]', Data.amount_total);
      noscriptContent = noscriptContent.replace('[OrderID]', order_id);
      useNoScript(noscriptContent);
      setDonorEmail(Data.user.email);
      let tempItems = Data.paypal_order_products.map(item => {
        return {
          item_id: item.product.creator,
          item_name: item.product.name || '',
          item_brand: item.product.category ? item.product.category.name : '',
          item_category: item.product.category ? item.product.category.name : '',
          item_description: item.product.category ? item.product.category.description : '',
          price: item.value,
          quantity: item.quantity,
        };
      });
      let adroll_obj = {
        ORDER_ID: `${order_id}`,
        USER_ID: `${Data.user.id}`,
        conversion_value: `${Data.amount_total}`,
        currency: `${Data.currency}`,
        products: Data.paypal_order_products.map(item => {
          return {
            price: item.value,
            product_id: item.product.creator,
            quantity: item.quantity,
          };
        }),
      };
      if (typeof adroll !== 'undefined') {
        // Call the function with adroll_obj
        TrackAdrollGlobalPixelUKScript(adroll_obj);
      } else {
        // Wait for the AdRoll library to load
        window.adrollLoaded = function () {
          TrackAdrollGlobalPixelUKScript(adroll_obj);
        };
      }
      let adminCost =
        Data.paypal_order_products[Data.paypal_order_products.length - 1].product.name === 'Admin Contribution'
          ? Number(Data.paypal_order_products[Data.paypal_order_products.length - 1].value)
          : 0;

      let _user_phone = formatPhoneNumber(Data.user.phone);
      let dataLayerTemp = {
        event: 'purchase',
        value_aud: parseFloat(handleConvertToAud(Data.currency, Data.amount_total)),
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.user.stripe_customer_id ? Data.user.stripe_customer_id : Data.user.new_stripe_customer_id}`,
        frequency: HandleShowRecurring('one-off'),
        ecommerce: {
          transaction_id: Data.paypal_transaction_id,
          shipping_tier: 'Paypal',
          payment_type: 'Apple Pay',
          value: parseFloat(Data.amount_total),
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      let dataLayerOrderCompleted = {
        event: 'order_completed',
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        // user_email_hashed: Base64.encode(Data.user.email),
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.user.stripe_customer_id ? Data.user.stripe_customer_id : Data.user.new_stripe_customer_id}`,
        frequency: HandleShowRecurring('one-off'),
        ecommerce: {
          transaction_id: Data.paypal_transaction_id,
          shipping_tier: 'Paypal',
          payment_type: 'Apple Pay',
          value: parseFloat(Data.amount_total),
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      // if (Data.paypal_order_products[0].value === 1) {
      //   dataLayerTemp.ecommerce.items[0].price = Data.paypal_order_products[0].quantity
      //   dataLayerTemp.ecommerce.items[0].quantity = Data.paypal_order_products[0].value
      // }
      // console.log("dataLayerTemp", dataLayerTemp)
      setModelDataLayer(dataLayerTemp);
      window.dataLayer = window.dataLayer || [];
      // IsGTMScriptLoaded() && window.dataLayer.push(dataLayerOrderCompleted);
      // IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
      function checkAndPushCustomEvent() {
        // if (checkGTMLoad()) {
        //   window.dataLayer.push(dataLayerOrderCompleted);
        //   window.dataLayer.push(dataLayerTemp);
        // } else {
        //   setTimeout(checkAndPushCustomEvent, 100);
        // }
        window.dataLayer.push(dataLayerOrderCompleted);
        window.dataLayer.push(dataLayerTemp);
        // let tempMuslimAd = getMuslimeAdId();
        // const scriptContent = `gtagMuslimAdNetwork('event', 'purchase', {
        //   'allow_custom_scripts': true,
        //   'value': '${Data.amount_total}',
        //   'transaction_id': '${Data.paypal_transaction_id}',
        //   'send_to': ['DC-13776505-saleik01/matwp0+transactions','${tempMuslimAd}']
        //   });`;
        // customUseScript('', scriptContent);
      }
      checkAndPushCustomEvent();
      if (CURRENCY !== Data.currency) {
        params.set('currency', Data.currency);
        // navigate(`/success?${params.toString()}`)
      }
      // setTtqPayoad(true);
      let tempContents = Data.paypal_order_products.map(item => {
        return {
          price: item.value,
          quantity: item.quantity,
          content_id: item.product.creator,
          content_category: item.product.category ? item.product.category.name : '',
          content_name: item.product.name || '',
          brand: item.product.category ? item.product.category.name : '',
        };
      });
      let obj = {
        content_type: 'product',
        contents: tempContents,
        currency: Data.currency.toUpperCase(),
        value: parseFloat(Data.amount_total),
        description: '',
      };
      setTimeout(() => {
        // window.ttq && window.ttq.instance(pixelID).track('Complete--Payment', {
        if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org') || checkDomain('matwproject.org.uk'))) {
          window.ttq &&
            window.ttq.track('CompletePayment', {
              //handlePaypalOrder
              ...obj,
            });
        }
        let _user_phone = formatPhoneNumber(Data.user.phone);
        window.ttq &&
          window.ttq.identify({
            email: Data.user.email,
            phone_number: _user_phone,
            external_id: `${Data.user.id}`,
          });
      }, 3000);



    } catch (error) {
      console.error('error========', error);
    }
  };
  const handlePaypalSubscription = async subscription_id => {
    try {
      const response = await services.getPaypalSubscriptionReceipt(subscription_id);
      handleClearUserBasket();
      // console.log('response getPaypalOrderReceipt', response);
      const Data = response.data;
      customUseScript(
        '',
        `  (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
      tap('create', '44176-eecb19', { integration: "javascript" });
      tap('conversion', 'paypal-${subscription_id}', '${parseFloat(Data.plan.unit_amount)}', {currency:'${Data.currency}'});`,
      );
      window.dataLayer = window.dataLayer || [];
      let dataLayerTemp = {
        event: 'purchase',
        value_aud: parseFloat(handleConvertToAud(Data.currency, Data.plan.unit_amount)),
        ecommerce: {
          currency: Data.currency.toUpperCase(),
          value: parseFloat(Data.plan.unit_amount),
          // transaction_id: `p-${Data.id}`,
          transaction_id: Data.payment_intent,
          payment_type: 'Paypal',
          tax: 0,
          items: Data?.paypal_order_products?.map(i => ({
            item_name: i.product.name,
            item_id: i.product.creator,
            item_brand: i.product.category.name || '',
            item_category: i.product.category.name || '',
            item_description: i.product.description || '',
            item_category2: '',
            price: i.value === 1 ? i.quantity : i.value,
            quantity: i.value === 1 ? 1 : i.quantity,
          })),
        },
      };
      let adroll_obj = {
        ORDER_ID: `${subscription_id}`,
        USER_ID: `${Data.user.id}`,
        conversion_value: `${Data.amount_total}`,
        currency: `${Data.currency}`,
        products: Data?.paypal_order_products?.map(item => {
          return {
            price: item.value,
            product_id: item.product.creator,
            quantity: item.quantity,
          };
        }),
      };
      if (typeof adroll !== 'undefined') {
        // Call the function with adroll_obj
        TrackAdrollGlobalPixelUKScript(adroll_obj);
      } else {
        // Wait for the AdRoll library to load
        window.adrollLoaded = function () {
          TrackAdrollGlobalPixelUKScript(adroll_obj);
        };
      }
      // if (Data.plan.unit_amount === 1) {
      //   dataLayerTemp.ecommerce.items[0].price = Data.quantity;
      //   dataLayerTemp.ecommerce.items[0].quantity = Data.plan.unit_amount;
      // }
      setModelDataLayer(dataLayerTemp);
      IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
      if (CURRENCY !== Data.currency) {
        params.set('currency', Data.currency);
        // navigate(`/success?${params.toString()}`)
      }
      // setTtqPayoad(true);
      let tempContents = Data.paypal_order_products.map(item => {
        return {
          price: item.value,
          quantity: item.quantity,
          content_id: item.product.creator,
          content_category: item.product.category ? item.product.category.name : '',
          content_name: item.product.name || '',
          brand: item.product.category ? item.product.category.name : '',
        };
      });
      let obj = {
        content_type: 'product',
        contents: tempContents,
        currency: Data.currency.toUpperCase(),
        value: parseFloat(Data.amount_total),
        description: '',
      };
      setTimeout(() => {
        // window.ttq && window.ttq.instance(pixelID).track('Complete--Payment', {
        if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org') || checkDomain('matwproject.org.uk'))) {
          window.ttq &&
            window.ttq.track('CompletePayment', {
              //handlePaypalSubscription
              ...obj,
            });
        }
        let _user_phone = formatPhoneNumber(Data.user.phone);
        window.ttq &&
          window.ttq.identify({
            email: Data.user.email,
            phone_number: _user_phone,
            external_id: `${Data.user.id}`,
          });
      }, 3000);



    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleDailyRamadan = async order_id => {
    try {
      const response = await services.getFlexibleStripeReceipt(order_id);
      console.log("response of daily=", response)
      handleClearUserBasket();
      setDailyDonationInfo(response.data)
      const Data = response.data;
      customUseScript(
        '',
        `  (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
      tap('create', '44176-eecb19', { integration: "javascript" });
      tap('conversion', 'stripe-${Data.checkout_identity}', '${parseFloat(Data.amount_total)}', {currency:'${Data.currency}'});`,
      );
      let noscriptContent =
        // eslint-disable-next-line no-template-curly-in-string
        '<img src="https://ad.doubleclick.net/ddm/activity/src=13776505;type=saleik01;cat=matwp0;qty=1;cost=[Revenue];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[OrderID]?" width="1" height="1" alt=""/>';
      noscriptContent = noscriptContent.replace('[Revenue]', Data.amount_total);
      noscriptContent = noscriptContent.replace('[OrderID]', order_id);
      useNoScript(noscriptContent);

      // let phone = Data.user.phone;
      // if (phone.startsWith('00')) {
      //   phone = phone.slice(2);
      // } else if (phone.startsWith('0')) {
      //   phone = phone.slice(1);
      // }
      // const reportData = {
      //   // phone,
      //   orderId: Data.checkout_identity || Data.id,
      //   totalValue: parseFloat(Data.amount_total),
      //   currency: Data.currency,
      //   type: 'MONETARY_EVENT',
      //   subType: 'purchase',
      // };
      // console.log('befor sending');
      // await services.sendReportToGupShup(reportData);
      // console.log('after sending');
      // `${parseFloat(Data.amount_total)}`, `{currency:${Data.currency}}`
      setDonorEmail(Data.user.email);
      let tempItems = [];
      let adroll_obj = {};
      const setEcommerceItems = () => {
        let tempProducts = [];
        Data.gateway_data.donation_schedule[0].products.map(item => {
          let tempPrd = getProduct(item.product_id)
          tempProducts.push({
            creator: tempPrd.creator,
            price: tempPrd[CURRENCY.toLowerCase()],
            quantity: item.amount,
          })
          tempItems.push({
            item_id: tempPrd.creator,
            item_name: tempPrd.name,
            item_brand: '',
            item_category: '',
            item_description: tempPrd.description || '',
            price: tempPrd[CURRENCY.toLowerCase()],
            quantity: item.amount,
          });
          return item;
        });
        adroll_obj = {
          ORDER_ID: `${Data.checkout_identity ? Data.checkout_identity : order_id}`,
          USER_ID: `${Data.user.id}`,
          conversion_value: `${0}`,
          currency: `${CURRENCY}`,
          products: tempProducts.length && tempProducts.map(item => {
            return {
              price: item.price,
              product_id: item.creator,
              quantity: item.quantity,
            };
          }),
        };
      }
      // Check every 1 second (1000 milliseconds)
      const intervalId = setInterval(() => {
        // Stop checking if the condition is met and tempItems is populated
        if (allProducts.length > 0) {
          setEcommerceItems();
          clearInterval(intervalId);
        }
      }, 1000);

      // Check if the AdRoll library is loaded
      if (typeof adroll !== 'undefined') {
        // Call the function with adroll_obj
        TrackAdrollGlobalPixelUKScript(adroll_obj);
      } else {
        // Wait for the AdRoll library to load
        window.adrollLoaded = function () {
          TrackAdrollGlobalPixelUKScript(adroll_obj);
        };
      }
      let adminCost = 0;

      // let _user_phone = `+${Data.user.phone.replace(/^0+/, '')}`;
      let _user_phone = formatPhoneNumber(Data.user.phone);
      let dataLayerTemp = {
        event: 'purchase',
        value_aud: 0,
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.user.checkout_identity ? Data.user.checkout_identity : order_id}`,
        frequency: 'Scheduled',
        ecommerce: {
          transaction_id: Data.checkout_identity ? Data.checkout_identity : Data.id,
          shipping_tier: 'Stripe',
          payment_type: 'Google Pay',
          value: 0,
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      let dataLayerOrderCompleted = {
        event: 'order_completed',
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.checkout_identity ? Data.checkout_identity : order_id}`,
        frequency: 'Scheduled',
        ecommerce: {
          transaction_id: Data.checkout_identity ? Data.checkout_identity : Data.id,
          shipping_tier: 'Stripe',
          payment_type: 'Google Pay',
          value: 0,
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      setModelDataLayer(dataLayerTemp);
      window.dataLayer = window.dataLayer || [];
      function checkAndPushCustomEvent() {
        window.dataLayer.push(dataLayerOrderCompleted);
        window.dataLayer.push(dataLayerTemp);
      }
      checkAndPushCustomEvent();
      // console.log("currency", Data.checkout_session_products[0].stripe_price_point.currency.name, CURRENCY)
      if (CURRENCY.toUpperCase() !== Data.currency.toUpperCase()) {
        params.set('currency', Data.currency.toUpperCase());
        // navigate(`/success?${params.toString()}`)
      }
      let tempContents2 = tempItems.map(item => {
        return {
          price: item.price,
          quantity: item.quantity,
          content_id: item.item_id,
          content_category: item.item_category,
          content_name: item.item_name,
          brand: item.item_brand,
        };
      });
      let obj2 = {
        content_type: 'product',
        contents: tempContents2,
        currency: Data.currency.toUpperCase(),
        value: parseFloat(Data.amount_total),
        description: '',
      };
      setTimeout(() => {
        // console.log("settime-", obj2)
        // window.ttq && window.ttq.instance(pixelID).track('Complete--Payment', {
        window.ttq &&
          window.ttq.track('CompletePayment', {
            //handleStripeOrder
            ...obj2,
          });
        let _user_phone = formatPhoneNumber(Data.user.phone);
        window.ttq &&
          window.ttq.identify({
            email: Data.user.email,
            phone_number: _user_phone,
            external_id: `${Data.user.id}`,
          });
      }, 3000);
      setTtqPayoad(true);
      let tempContents = tempItems.map(item => {
        return {
          price: item.price,
          quantity: item.quantity,
          content_id: item.item_id,
          content_category: item.item_category,
          content_name: item.item_name,
          brand: item.item_brand,
        };
      });
      let obj = {
        content_type: 'product',
        contents: tempContents,
        currency: Data.currency.toUpperCase(),
        value: 0,
        description: '',
        email: Data.user.email,
        phone_number: _user_phone,
        user_id: Data.user.id,
      };
      setEventPayload(obj);
    } catch (error) {
      console.error('error======== on stripe', error);
    }
  };
  const getProduct = creator => {
    let prd
    allProducts.length && allProducts.map(item => {
      if (item.creator === creator) {
        prd = item
      }
      return item
    });
    return prd;
  }
  const customUseScript = (url, content) => {
    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = content;
    document.getElementsByTagName('head')[0].appendChild(script);
  };
  const useNoScript = content => {
    const script = document.createElement('noscript');
    script.innerHTML = content;
    document.body.prepend(script);
  };
  // const checkGTMLoad = () => {
  //   var _datalayer = window.dataLayer;
  //   for (var i = 0; i < _datalayer.length; i++) {
  //     if (_datalayer[i].event === 'gtm.load') {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const getAllProducts = async () => {
    let tempPrds = [];
    let tempAllPrds = [];
    try {
      const response = await services.allProducts();
      response.data.map(item => {
        item.sub_categories.map(inner_item => {
          let tempSubCat = inner_item;
          tempSubCat.products = inner_item.products.filter(inner_item_product => {
            if (Number(inner_item_product.status) === 1) {
              tempAllPrds.push(inner_item_product)
              if (inner_item_product.creator === 'TK-QB-2024-EG28') tempPrds.push(inner_item_product);
              if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') tempPrds.push(inner_item_product);
            }
            return inner_item_product;
          });
          return tempSubCat;
        });
        return item;
      });
      setAllProducts(tempAllPrds)
      setProducts(tempPrds);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const customUseScriptOnHead = src => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
  };
  const handleClearUserBasket = () => {
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempBasket = basketStatesFromLocalStorage;
    if (tempBasket) {
      tempBasket.products = [
        {
          product: null,
          quantity: 1,
          amount: 0,
          total: 0,
          currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
          recurring: 'one-off',
          landing_page: '',
          onBehalf: 'For the sake of Allah SWT',
        },
      ];
      tempBasket.adminCost = 0;
      tempBasket.grandTotal = 0;
      tempBasket.total = 0;
      HandleLocalStorage(tempBasket);
    }
  };
  // const getMuslimeAdId = () => {
  //   if (window.location.hostname) {
  //     switch (window.location.hostname) {
  //       case 'matwproject.org':
  //         setMuslimAdFloodLight('G-RLDP9FNHPC');
  //         return 'G-RLDP9FNHPC';
  //       case 'matwproject.org.uk':
  //         setMuslimAdFloodLight('G-S2L82RJ0RB');
  //         return 'G-S2L82RJ0RB';
  //       case 'matwprojectusa.org':
  //         setMuslimAdFloodLight('G-VV0GWEZGJB');
  //         return 'G-VV0GWEZGJB';
  //       case 'matwprojectca.org':
  //         setMuslimAdFloodLight('G-6GBMQEJJ3Z');
  //         return 'G-6GBMQEJJ3Z';
  //       case 'matwprojectfr.org':
  //         setMuslimAdFloodLight('G-4NV6XR2SQ0');
  //         return 'G-4NV6XR2SQ0';
  //       case 'matwprojectme.org':
  //         setMuslimAdFloodLight('G-YF5EL2J2LF');
  //         return 'G-YF5EL2J2LF';
  //       case 'matwprojectmys.org':
  //         setMuslimAdFloodLight('G-Y76TJCCHGX');
  //         return 'G-Y76TJCCHGX';
  //       case 'matwprojectsgp.org':
  //         setMuslimAdFloodLight('G-N1MBFGB7F2');
  //         return 'G-N1MBFGB7F2';
  //       case 'matwprojectid.org':
  //         setMuslimAdFloodLight('G-JMY9DWRNEL');
  //         return 'G-JMY9DWRNEL';
  //       case 'matwprojectie.org':
  //         setMuslimAdFloodLight('G-HX6KK25K2C');
  //         return 'G-HX6KK25K2C';
  //       case 'matwprojectde.org':
  //         setMuslimAdFloodLight('G-B0ZPS41B7P');
  //         return 'G-B0ZPS41B7P';
  //       default:
  //         setMuslimAdFloodLight('G-RLDP9FNHPC');
  //         return 'G-RLDP9FNHPC';
  //     }
  //   }
  // };
  const formatPhoneNumber = phone => {
    // Trim extra spaces
    // Remove all spaces
    let formattedPhone = phone.replace(/\s+/g, '');
    // Replace "00" at the start with "+"
    if (formattedPhone.startsWith('00')) {
      formattedPhone = '+' + formattedPhone.slice(2);
    }
    return formattedPhone;
  };
  const handleAdyenOrder = async order_id => {
    // console.log("complete payment", order_id)
    try {
      const response = await services.getStripeReceipt(order_id);
      handleClearUserBasket();
      // console.log('response getStripeReceipt', response);
      const Data = response.data.checkout_session;
      customUseScript(
        '',
        `  (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
    tap('create', '44176-eecb19', { integration: "javascript" });
    tap('conversion', 'stripe-${Data.payment_intent}', '${parseFloat(Data.amount_total)}', {currency:'${Data.currency}'});`,
      );
      // const scriptContent = `gtagMuslimAdNetwork('event', 'purchase_campaign', {
      // 'allow_custom_scripts': true,
      // 'value': '${Data.amount_total}',
      // 'transaction_id': '${Data.payment_intent}',
      // 'send_to': 'DC-13776505/saleik01/matwp0+transactions'
      // });`;
      //customUseScript('', scriptContent);
      let noscriptContent =
        // eslint-disable-next-line no-template-curly-in-string
        '<img src="https://ad.doubleclick.net/ddm/activity/src=13776505;type=saleik01;cat=matwp0;qty=1;cost=[Revenue];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[OrderID]?" width="1" height="1" alt=""/>';
      noscriptContent = noscriptContent.replace('[Revenue]', Data.amount_total);
      noscriptContent = noscriptContent.replace('[OrderID]', order_id);
      useNoScript(noscriptContent);
      setDonorEmail(Data.user.email);
      let tempItems = Data.checkout_session_products.map(item => {
        return {
          item_id: item.stripe_price_point.product.creator,
          item_name: item.stripe_price_point.product.name,
          item_brand: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
          item_category: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
          item_description: item.stripe_price_point.product.description || '',
          price: item.stripe_price_point.unit_amount,
          quantity: item.quantity,
        };
      });
      let adroll_obj = {
        ORDER_ID: `${Data.payment_intent ? Data.payment_intent : order_id}`,
        USER_ID: `${Data.user.id}`,
        conversion_value: `${Data.amount_total}`,
        currency: `${Data.checkout_session_products[0].stripe_price_point.currency.name}`,
        products: Data.checkout_session_products.map(item => {
          return {
            price: item.stripe_price_point.unit_amount,
            product_id: item.stripe_price_point.product.creator,
            quantity: item.quantity,
          };
        }),
      };
      // Check if the AdRoll library is loaded
      if (typeof adroll !== 'undefined') {
        // Call the function with adroll_obj
        TrackAdrollGlobalPixelUKScript(adroll_obj);
      } else {
        // Wait for the AdRoll library to load
        window.adrollLoaded = function () {
          TrackAdrollGlobalPixelUKScript(adroll_obj);
        };
      }
      let adminCost =
        Data.checkout_session_products[Data.checkout_session_products.length - 1].stripe_price_point.product.name === 'Admin Contribution'
          ? Number(Data.checkout_session_products[Data.checkout_session_products.length - 1].stripe_price_point.unit_amount)
          : 0;
      let _user_phone = Data.user?.phone
        ? `+${Data.user.phone.replace(/^0+/, '')}`
        : null;
      let dataLayerTemp = {
        event: 'purchase',
        value_aud: parseFloat(handleConvertToAud(Data.checkout_session_products[0].stripe_price_point.currency.name, Data.amount_total)),
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.user.stripe_customer_id ? Data.user.stripe_customer_id : Data.user.new_stripe_customer_id}`,
        frequency: HandleShowRecurring(Data.checkout_session_products[0].stripe_price_point.recurring),
        ecommerce: {
          transaction_id: Data.payment_intent ? Data.payment_intent : Data.id,
          shipping_tier: 'Adyen',
          payment_type: 'Google Pay',
          value: parseFloat(Data.amount_total),
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      let dataLayerOrderCompleted = {
        event: 'order_completed',
        name: Data.user.name,
        email: Data.user.email,
        phone: _user_phone,
        user_email_hashed: sha256(Data.user.email),
        user_id: `${Data.user.id}`,
        pay_id: `${Data.user.stripe_customer_id ? Data.user.stripe_customer_id : Data.user.new_stripe_customer_id}`,
        frequency: HandleShowRecurring(Data.checkout_session_products[0].stripe_price_point.recurring),
        ecommerce: {
          transaction_id: Data.payment_intent ? Data.payment_intent : Data.id,
          shipping_tier: 'Adyen',
          payment_type: 'Google Pay',
          value: parseFloat(Data.amount_total),
          tax: adminCost,
          currency: Data.currency.toUpperCase(),
          items: tempItems,
        },
      };
      setModelDataLayer(dataLayerTemp);
      window.dataLayer = window.dataLayer || [];
      function checkAndPushCustomEvent() {
        window.dataLayer.push(dataLayerOrderCompleted);
        window.dataLayer.push(dataLayerTemp);
      }
      checkAndPushCustomEvent();
      // console.log("currency", Data.checkout_session_products[0].stripe_price_point.currency.name, CURRENCY)
      if (CURRENCY !== Data.checkout_session_products[0].stripe_price_point.currency.name) {
        params.set('currency', Data.checkout_session_products[0].stripe_price_point.currency.name);
        // navigate(`/success?${params.toString()}`)
      }
      let tempAdyenContents = tempItems.map(item => {
        return {
          price: item.price,
          quantity: item.quantity,
          content_id: item.item_id,
          content_category: item.item_category,
          content_name: item.item_name,
          brand: item.item_brand,
        };
      });
      let adyenObj = {
        content_type: 'product',
        contents: tempAdyenContents,
        currency: Data.currency.toUpperCase(),
        value: parseFloat(Data.amount_total),
        description: '',
      };
      setTimeout(() => {
        if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org') || checkDomain('matwproject.org.uk'))) {
          window.ttq &&
            window.ttq.track('CompletePayment', {
              ...adyenObj,
            });
        }
        let _user_phone = Data.user?.phone
          ? `+${Data.user.phone.replace(/^0+/, '')}`
          : null;
        window.ttq &&
          window.ttq.identify({
            email: Data.user.email,
            phone_number: _user_phone,
            external_id: `${Data.user.id}`,
          });
      }, 3000);
      setTtqPayoad(true);
      let tempContents = Data.checkout_session_products.map(item => {
        return {
          price: item.stripe_price_point.unit_amount,
          quantity: item.quantity,
          content_id: item.stripe_price_point.product.creator,
          content_category: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
          content_name: item.stripe_price_point.product.name || '',
          brand: item.stripe_price_point.product.category ? item.stripe_price_point.product.category.name : '',
        };
      });
      let obj = {
        content_type: 'product',
        contents: tempContents,
        currency: Data.currency.toUpperCase(),
        value: parseFloat(Data.amount_total),
        description: '',
        email: Data.user.email,
        phone_number: _user_phone,
        user_id: Data.user.id,
      };
      setEventPayload(obj);



    } catch (error) {
      console.error('error========', error);
    }
  };
  const addTrackingScripts = () => {
    // İlk script
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.text = `
      var u="aHR0cHM6Ly9nZW8tdHJhY2tlci5zbWFkZXguY29tL2h5cGVyYWQvcGl4ZWwtdHJhY2tpbmc/b3JkZXI9MTE0NzM1JmFjdGlvbj1yZWdpc3RyYXRpb24=";
      u = atob(u);
      var t=new Date().getTime();
      var ut=u+"&rand="+t;
      var it=document.createElement("img");
      it.src=ut;
      it.border=0;
    `;
    document.body.appendChild(script1);

    // İkinci script
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `
      var u="aHR0cHM6Ly90cmFja2JhY2suZ290cmFja2llci5jb20vcGl4ZWw/YWRpZD02NzFmNTE0NjRlOWIyODExMzA3Y2Q3MTE=";
      u = atob(u);
      var ut=u;
      var it=document.createElement("img");
      it.src=ut;
      it.border=0;
    `;
    document.body.appendChild(script2);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log('params', params.toString(), params, gateway, mode);

    // console.log('sha256', sha256('rayhaanhussain.mohammed@gmail.com'));
    if (gateway === 'paypal') {
      if (MODE === 'subscription') {
        handlePaypalSubscription(PayPalID);
      } else {
        handlePaypalOrder(PayPalID);
      }
    } else if (gateway === 'stripe') {
      if (MODE === 'flexible') {
        handleDailyRamadan(FlexibleID)
      } else {
        handleStripeOrder(ID);
      }
    } else if (gateway === 'adyen') {
      handleAdyenOrder(ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway]);

  useEffect(() => {
    getAllProducts();
    // handleGoogleTagManager(window.location.href);
    // console.log("successpage", window);
    // console.log('', muslimAdFloodLight);
    handleConvertToAud('EUR', 4.5);
    // AddAdrollGlobalPixelUKScript();
    if (window.location.hostname) {
      switch (window.location.hostname) {
        case 'matwproject.org.uk':
          //disable pagesense customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/c5fee5fb9e2c4549a414161c2ec990ae.js');
          break;
        case 'matwprojectusa.org':
          //disable pagesense customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/745e32aa2459469baaffff6cf38fd1a0.js');
          break;
        case 'matwprojectca.org':
          //disable pagesense customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/6870f7c1d5004f639d08724f4ce631fc.js');
          break;
        case 'matwprojectme.org':
          //disable pagesense customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/6e50ca4065ed4ebeb0edadc7bbe5ea3c.js');
          break;
        case 'matwprojectmys.org':
          //disable pagesense customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/58682877a41c4e5cb32ce21666906ae4.js');
          break;
        case 'matwprojectsgp.org':
          //disable pagesense customUseScriptOnHead('https://cdn.pagesense.io/js/matwinternational/83f6abdb055f4ca48edf8693ee0ddad0.js');
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ID && checkDomain('matwproject.org')) {
      const storageKey = 'session_ids';
      let sessionIds = JSON.parse(localStorage.getItem(storageKey)) || [];
      if (!sessionIds.includes(ID)) {
        sessionIds.push(ID);
        localStorage.setItem(storageKey, JSON.stringify(sessionIds));
        addTrackingScripts();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Attach a load event listener to the window

    window?.addEventListener('load', handlePageLoad);
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://206788.t.hyros.com/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL) ;head.appendChild(script);`)
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://t.matwprojectusa.org/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL);head.appendChild(script);`)
    // if(window?.ttq?.track && ttqPayload) {
    //   customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://t.matwprojectusa.org/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL);head.appendChild(script);`)
    // }
    AddUniversalUSAScript();
    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window?.removeEventListener('load', handlePageLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.ttq?.track, ttqPayload]);
  const handlePageLoad = () => {
    // console.log("handle-PageLoad=")
    if (ttqPayload && window.ttq && Object.keys(eventPayload).length > 0) {
      // console.log("handle-PageLoad on if=")
      setTtqPayoad(false);
      setTimeout(() => {
        let obj = {
          content_type: 'product',
          contents: eventPayload.contents,
          currency: eventPayload.currency.toUpperCase(),
          value: parseFloat(eventPayload.value),
          description: eventPayload.description,
        };
        // window.ttq && window.ttq.instance(pixelID).track('Complete--Payment', {
        if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org') || checkDomain('matwproject.org.uk'))) {
          window.ttq &&
            window.ttq.track('CompletePayment', {
              ...obj,
            });
        }
        window.ttq &&
          window.ttq.identify({
            email: eventPayload.email,
            // phone_number: `+${eventPayload.phone}`,
            phone_number: eventPayload.phone,
            external_id: `${eventPayload.user_id}`,
          });
      }, 3500);
    }
  };
  useEffect(() => {
    // console.log("window=",window.navigation)
    if (!window.navigation || !window.navigation.pagetype) {
      // Set a default value
      window.navigation = window.navigation || {};
      // window.navigation.pagetype = 'webpage';
      window.navigation.pagetype = window.navigation.pagetype ? window.navigation.pagetype + 1 : 0;
      // console.log("window in=",window.navigation)
    } else {
      window.navigation.pagetype = window.navigation.pagetype ? window.navigation.pagetype + 1 : 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {

  //   ramdanProducts && foodProducts && Object.keys(foodProducts).length && !isLoaded && getProductData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [foodProducts, ramdanProducts])
  useEffect(() => {
    const fetchPopup = async () => {
      try {
        const settings = await fetchPopupSettings();
        const isMobileAppPopupEnabled = settings.some(
          popup => popup.popup_type === 'mobileapp' && popup.enabled === true
        );
        setShowAppleMeta(isMobileAppPopupEnabled);
      } catch (err) {
        console.error("Popup fetch error:", err);
        setShowAppleMeta(false);
      }
    };

    fetchPopup();
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div className=" md:container relative mx-auto overflow-hidden">
      <Helmet>
        <meta name="robots" content="noindex" />
        {showAppleMeta && (
          <meta name="apple-itunes-app" content="app-id=6446399296" />
        )}
        <title>Thank You | MATW Project | 100% Donation Policy</title>

        <link rel="alternate" href={`https://matwproject.org.uk${window.location.pathname}`} hreflang="en-gb" />
        <link rel="alternate" href={`https://matwproject.org${window.location.pathname}`} hreflang="x-default" />
        <link rel="alternate" href={`https://matwprojectca.org${window.location.pathname}`} hreflang="en-ca" />
        <link rel="alternate" href={`https://matwproject.org/fr${window.location.pathname}`} hreflang="fr-fr" />
        <link rel="alternate" href={`https://matwprojectusa.org${window.location.pathname}`} hreflang="en-us" />
        <link rel="alternate" href={`https://matwprojectid.org${window.location.pathname}`} hreflang="en-id" />
        <link rel="alternate" href={`https://matwprojectmys.org${window.location.pathname}`} hreflang="en-my" />
        <link rel="alternate" href={`https://matwprojectsgp.org${window.location.pathname}`} hreflang="en-sg" />
      </Helmet>
      <Confetti width={window.innerWidth} height={window.innerHeight} />
      <div className="w-full flex justify-center  absolute top-8 left-0 z-10">
        <div className="p-2 bg-white rounded-full w-24 shadow-md ">
          {/* <img src="/images/output-onlinepngtools (2).png" alt="logo" className=" w-20 " /> */}
          <a href="/">
            <img className="w-20 md:w-[80px] rounded-full" src="/images/general/logo-.png" alt="matw" />
          </a>
        </div>
      </div>
      {/* Start datalayer */}
      {modelDataLayer && modelDataLayer.ecommerce.currency && (
        <div className="gtag_datalayer hidden" id="gtag_datalayer">
          <div className="gtag_ecommerce" id="gtag_ecommerce">
            <div className="gtag_currency" id="gtag_currency">
              {modelDataLayer.ecommerce.currency}
            </div>
            <div className="gtag_items" id="gtag_items">
              {modelDataLayer.ecommerce.items.map((item, index) => {
                return (
                  <div key={`gtagsitems${index}`}>
                    <div className="gtag_item_brand" id={`gtag_item_brand_${index}`}>
                      {item.item_brand}
                    </div>
                    <div className="gtag_item_category" id={`gtag_item_category_${index}`}>
                      {item.item_category}
                    </div>
                    <div className="gtag_item_category2" id={`gtag_item_category2_${index}`}>
                      {item.item_category2}
                    </div>
                    <div className="gtag_item_id" id={`gtag_item_id_${index}`}>
                      {item.item_id}
                    </div>
                    <div className="gtag_item_name" id={`gtag_item_name_${index}`}>
                      {item.item_name}
                    </div>
                    <div className="gtag_price" id={`gtag_price_${index}`}>
                      {item.price}
                    </div>
                    <div className="gtag_quantity" id={`gtag_quantity_${index}`}>
                      {item.quantity}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="gtag_payment_type" id="gtag_payment_type">
              {modelDataLayer.ecommerce.payment_type}
            </div>
            <div className="gtag_tax" id="gtag_tax">
              {modelDataLayer.ecommerce.tax}
            </div>
            <div className="gtag_transaction_id" id="gtag_transaction_id">
              {modelDataLayer.ecommerce.transaction_id}
            </div>
            <div className="gtag_value" id="gtag_value">
              {modelDataLayer.ecommerce.value}
            </div>
          </div>
          <div className="gtag_event" id="gtag_event">
            {modelDataLayer.event}
          </div>
        </div>
      )}
      {/* End datalayer */}
      <div className="flex flex-wrap justify-center py-8 px-3 md:px-0">
        <div className="bg-white flex pt-20 flex-col basis-full lg:basis-9/12 xl:basis-7/12 rounded-md px-2 md:px-6 py-12 shadow-md mt-10 items-center">
          {/* <div className="rounded-full bg-green-50 p-6 flex justify-center items-center border border-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 761.12 760.87" fill="currentColor" className="text-green-700 w-12 h-12">
              <path d="M756.19,340c-8.32-35.28-26-65.37-53.06-89.67a6.42,6.42,0,0,1-2.49-6c1-10.17.1-20.27-1.14-30.38-5-41.07-22.54-76.12-52.21-104.64C611.18,74.53,567.47,58.61,517.44,60.1a9.14,9.14,0,0,1-7.67-3.19C464.11,9.41,408.68-8.66,344,3.84c-36.32,7-67.1,25.07-92.19,52.39a10.68,10.68,0,0,1-9.42,3.88,150.22,150.22,0,0,0-25.43.8c-27.78,3.1-53.81,11.58-76.87,27.28-54.91,37.37-81.56,89.62-79.91,156.13a7,7,0,0,1-2.52,6C25.75,279.76,6.42,315.87,1.36,359.15c-6.43,55,10,102.62,47.63,142.79,7.19,7.68,14.84,13.95,10.74,23.63,1.29,45.19,15.31,83.48,44,115.66,36.07,40.46,81.75,60,135.9,59.48,6-.06,9.88,1.45,14.11,5.79,41.26,42.23,91.66,60,150,52.8,41-5.09,76.06-23.35,104.31-53.84,3.36-3.62,6.62-5.11,11.55-4.81a153.69,153.69,0,0,0,47.5-4.92c48-12.29,84.85-39.65,110-82.4a167.76,167.76,0,0,0,23.43-92.59c-.19-5.47,1.13-9.18,5.22-12.91a159.83,159.83,0,0,0,32.4-40.95C760.29,426.85,766.69,384.48,756.19,340ZM519.85,311.88Q453.9,404,388,496.26c-4.93,6.89-9.35,14.19-15.12,20.45-11.27,12.23-28.53,13.29-41.17,2.43-4.53-3.9-8.61-8.33-12.85-12.57Q281.6,469.3,244.36,432c-11.72-11.76-12.78-28.12-2.73-40.07,11.6-13.78,30.74-14.44,44-1.29q29.08,28.85,57.89,58c3.05,3.09,4.3,3.45,7.07-.46Q411.16,362.95,472,277.94c6-8.4,13.2-14.45,24-15,16.92-.89,30.69,11.79,31.17,29.42C527.21,299.28,524.23,305.76,519.85,311.88Z" />
            </svg>
          </div> */}
          <h1 className="text-green-700 text-2xl font-medium mt-5">Alhamdulillah!</h1>
          <h1 className="text-green-700 text-2xl font-medium text-center">{MODE === 'flexible' ? 'Donation successfully automated' : 'Donation Complete!'}</h1>
          <div className="grid grid-cols-5 p-2- sm:p-4 w-full mt-2 gap-2 text-center">
            <p className="col-span-5 text-stone-500 mb-4 text-center">
              {MODE === 'flexible'
                // ? <>You have, Masha Allah, automated your donations for the {dailyDonationInfo ? dailyDonationInfo.gateway_data.donation_schedule.length : 0} days of Ramadan. We will send you a daily notification against your donation.
                //   <br /> Please view your receipt below, you can screenshot to save it.
                // </>
                ? <>Thank you for automating your Ramadan donations, this will help ensure you maximize on the blessings of this special month and not miss giving on Laylatul Qadr.
                  <br /> <strong className="mr-0.5">Plese note, you have not made a donation yet.</strong>Your donations will be processed as defined in your donation schedule.
                </>
                : 'We have received your donation, please click below to view your receipt, you can screenshot it to save.'}
            </p>

            <div className=" col-span-5 col-start-1 sm:col-start-2 sm:col-span-3 ">
              {Number(isFuture) === 0 && (
                <div
                  className={`btn w-full cursor-pointer flex items-center justify-between rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#00a3da] text-white focus:bg-[#f60362] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#f60362] hover:bg-[#f60362] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
                  onClick={() => handlePrint()}
                >
                  {MODE === 'flexible' ? 'View Donation Schedule' : 'View Receipt'}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-2">
                    <path
                      fillRule="evenodd"
                      d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div className="col-span-5 col-start-1 sm:col-start-2 sm:col-span-3 mt-4-">
              <Link
                to={`https://survey.zohopublic.com/zs/bED4fu?Email=${donorEmail}`}
                className={`mt-3 w-full flex items-center justify-between rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#b31641] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#b31641] hover:bg-[#b31641] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
              >
                <p>
                  Take Our Survey <span className="text-xs">(NEW!)</span>
                </p>
                <svg
                  style={{ color: 'white' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  fill="currentColor"
                  className="bi bi-instagram"
                  viewBox="0 0 15 16"
                >
                  <path
                    d="M11.2 0V1.6H13.6056C14.044 1.6 14.4 1.956 14.4 2.3944V15.2056C14.3998 15.4162 14.316 15.6182 14.1671 15.7671C14.0182 15.916 13.8162 15.9998 13.6056 16H0.7944C0.583712 16 0.381653 15.9163 0.232674 15.7673C0.0836955 15.6183 0 15.4163 0 15.2056V2.3944C0 1.956 0.356 1.6 0.7944 1.6H3.2V0H11.2ZM3.2 3.2H1.6V14.4H12.8V3.2H11.2V4.8H3.2V3.2ZM4.8 11.2V12.8H3.2V11.2H4.8ZM4.8 8.8V10.4H3.2V8.8H4.8ZM4.8 6.4V8H3.2V6.4H4.8ZM9.6 1.6H4.8V3.2H9.6V1.6Z"
                    fill="white"
                  />
                </svg>
              </Link>
            </div>
            <div className="col-span-5 col-start-1 sm:col-start-2 sm:col-span-3 mt-3">
              <p className="w-full text-[#00a3da] leading-[20px] text-center text-md mb-3">Download the new MATW Project app <br /> and make giving easier</p>
              <div className="flex justify-center items-center gap-6">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.matwproject.matw"
                >
                  <img src={androidAppLogo} alt="MATW Google App" className="w-32" />
                </Link>
                <Link
                  to="https://apps.apple.com/pk/app/matw-project-pay-zakat/id6446399296"
                >
                  <img src={iosAppLogo} alt="MATW IOS App" className="w-32" />
                </Link>
              </div>
            </div>
            {/* /* -------------------------------- Products --------------------------------  */}
            <div className="col-span-6 grid md:pr-2 gap-2">
              <div className="flex justify-center flex-wrap my-6  col-span-6">
                <h1 className="text-[#00a3da] text-center basis-full mb-4 text-xl md:text-2xl">Can you change more lives?</h1>
                {products.map((item, index) => {
                  return (
                    <div key={`data${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/2 p-2">
                      <ProductBox product={item} currency={selectedCurrencyStore} linkBtnClasses={'!min-w-[120px]'} removeDataLayer />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-5 col-start-1 sm:col-start-2 sm:col-span-3 mt-1">
              <Link
                to={generateLinkWithLang('/', lang)}
                // onClick={() => navigate('/')}
                className={`mb-3 w-full flex items-center justify-between rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#00a3da] text-white focus:bg-[#f60362] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#f60362] hover:bg-[#f60362] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
              >
                Donate More
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="ml-2 w-6 h-6">
                  <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                </svg>
              </Link>
              <Link
                to="/ali-banat"
                className={`mb-3 w-full flex items-center justify-between rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#00a3da] text-white focus:bg-[#f60362] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#f60362] hover:bg-[#f60362] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
              >
                Watch Ali Banat's Story
                <svg
                  style={{ color: 'white' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-youtube"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                    fill="white"
                  />
                </svg>
              </Link>
              <Link
                to="/zakat-calculator"
                className={`mb-3 w-full flex items-center justify-between rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#00a3da] text-white focus:bg-[#f60362] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#f60362] hover:bg-[#f60362] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
              >
                Calculate Your Zakat
                <svg
                  style={{ color: 'white' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-calculator"
                  viewBox="0 0 16 16"
                >
                  {' '}
                  <path
                    d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                    fill="white"
                  ></path>{' '}
                  <path
                    d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
                    fill="white"
                  />{' '}
                </svg>
              </Link>
              <Link
                to="https://instagram.com/matw_project"
                className={`mb-3 w-full flex items-center justify-between rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#00a3da] text-white focus:bg-[#f60362] focus:shadow-[0_2px_5px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] active:bg-[#f60362] hover:bg-[#f60362] hover:shadow-[0_4px_4px_-4px_rgba(255,0,67,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]`}
              >
                Live Updates On Instagram
                <svg
                  style={{ color: 'white' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                    fill="white"
                  />
                </svg>
              </Link>
            </div>
            <p className="col-span-5 text-stone-500 mb-4">
              Check out our most recent
              <Link to={`https://matwproject.org/annual-reports/`} target="_blank" className="text-blue-500 ml-1">
                project impact report.
              </Link>
            </p>
            {MODE === 'flexible' ? '' : <p className="col-span-5 text-stone-500 mb-4">
              To check or cancel any subscriptions{' '}
              <a href={`${window.location.protocol}//${window.location.host}/cancel`} className="text-blue-500">
                please visit this page.
              </a>
            </p>}
            <p className="col-span-5 text-stone-500 mb-4">
              Please note that if you are subscribed to regular donations and your payment fails, then the next payment will also take the previous
              payments and you will see multiple payments for that day.
            </p>
          </div>
        </div>
        <div className="p-2 sm:p-4 w-full mt-6 gap-2 flex flex-col col-span-12">
          <p className="w-full text-stone-400 text-center text-md mb-3">Follow us on Social Media</p>
          <div className="flex justify-center items-center gap-6">
            <Link
              to="https://www.facebook.com/matwproject/"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/facebook.png" alt="facebook" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://www.instagram.com/matw_project/"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/instagram.png" alt="instagram" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://twitter.com/matw_project"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/twitter.png" alt="twitter" className="w-auto h-[50%]" />
            </Link>
            <Link
              to="https://www.youtube.com/@MATWProject"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/youtube.png" alt="youtube" className="w-auto h-[60%]" />
            </Link>
            <Link
              to="https://www.tiktok.com/@matw_project"
              className="rounded-full border border-stone-200 h-[40px] w-[40px] flex justify-center items-center text-stone-500 transition-all duration-200 hover:bg-stone-100 hover:text-[#00a3da] hover:border-stone-400"
            >
              <img src="/images/social-media/tiktok.png" alt="tiktok" className="w-auto h-[60%]" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
