import { useRef, useState } from 'react';
// import ShowCurrencies from '../../../utils/showCurrencies';
import { useEffect } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
// import { IoCloseOutline } from 'react-icons/io5';
// import useIsExactDomain from '../../../hooks/cart/useIsExactDomain';

const AdminCosts = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { currency, selectedSubscribe, basketStates, onChange, comeFromHome, isCart, isSticky = false, isSheet = false } = props;
  const refQuantity = useRef(null);
  const [isChanged, setIsChanged] = useState(false);
  const _adminCost = currency.toLowerCase() === 'myr' ? 16 : 4;
  const [isUsa, setIsUsa] = useState(false);

  useEffect(() => {
    const href = window.location.href;
    if (href.includes('matwprojectusa.org')) {
      setIsUsa(true);
    } else {
      setIsUsa(false);
    }
  }, []);

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const ShowCurrencies2 = (currency, _price) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'AUD':
        _final = `$${price} AUD`;
        break;
      case 'CAD':
        _final = `$${price} CAD`;
        break;
      case 'MYR':
        _final = `${price} MYR`;
        break;
      case 'SGD':
        _final = `$${price} SGD`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  const options = [
    { value: 0, label: ShowCurrencies2(currency, 0) },
    { value: _adminCost, label: ShowCurrencies2(currency, _adminCost) },
    { value: _adminCost * 2, label: ShowCurrencies2(currency, _adminCost * 2) },
    { value: _adminCost * 3, label: ShowCurrencies2(currency, _adminCost * 3) },
    { value: _adminCost * 4, label: ShowCurrencies2(currency, _adminCost * 4) },
  ];
  const [selectedAdminCost, setSelectedAdminCost] = useState(options[1]);
  const handleSelectAdminCost = _index => {
    // console.log("handleSelectAdmin-Cost=", options, _index, options[_index])
    setSelectedAdminCost(options[_index]);
    onChange(options[_index].value, false);
  };
  const handleCheckingTotalAmount = _grandTotal => {
    if (_grandTotal > 0 && _grandTotal < 21) {
      handleSelectAdminCost(1);
    } else if (_grandTotal > 20 && _grandTotal < 51) {
      handleSelectAdminCost(2);
    } else if (_grandTotal > 50 && _grandTotal < 121) {
      handleSelectAdminCost(3);
    } else if (_grandTotal > 120) {
      // console.log(" more thant 120=", basketStates, basketStates.grandTotal)
      // console.log(" more thant 120=", Math.round((basketStates.grandTotal - basketStates.adminCost) / 10))
      let temp = {
        value: Math.round((basketStates.grandTotal - basketStates.adminCost) / 10),
        label: ShowCurrencies2(currency, Math.round((basketStates.grandTotal - basketStates.adminCost) / 10)),
      };
      // console.log(" more thant 120=", temp)
      setSelectedAdminCost(temp);
      onChange(temp.value, false);
    } else {
      handleSelectAdminCost(0);
    }
  };
  const handleQty = value => {
    let tempCount = basketStates.adminCost;
    if (parseInt(value) >= 0) {
      tempCount = parseInt(value);
    } else {
      tempCount = 0;
    }
    setIsChanged(true);
    onChange(tempCount, true);
  };
  const handleKeyDown = value => {
    let checkIfNum;
    if (value.key !== undefined) {
      checkIfNum = value.key === '.';
    }
    return checkIfNum && value.preventDefault();
  };
  const handleOnBlurQty = value => {
    if (Number(value) < 0) {
      handleQty(0);
    }
  };

  /* -------------------------------------------------------------------------- */

  /* -------------------------------- Useeffect ------------------------------- */
  useEffect(() => {
    let tempTotalProducts = 0;
    basketStates.products.map(item => {
      item.product ? (tempTotalProducts += item.total) : (tempTotalProducts = 0);
      return item;
    });
    console.log('handleCheckingTotal-Amount=', isChanged, tempTotalProducts, comeFromHome, selectedAdminCost);
    if (comeFromHome) {
      // !selectedAdminCost.value && handleSelectAdminCost(1)
      // // handleCheckingTotalAmount(tempTotalProducts)
      // if (!isChanged) {
      //     !selectedAdminCost.value && handleSelectAdminCost(1)
      //     handleCheckingTotalAmount(tempTotalProducts)
      // } else {
      //     // if (!tempTotalProducts) onChange(0)
      //     if (tempTotalProducts) handleCheckingTotalAmount(tempTotalProducts)
      // }
      // if (tempTotalProducts) handleCheckingTotalAmount(tempTotalProducts)
    }
    if (tempTotalProducts && !basketStates.customAdminFee) handleCheckingTotalAmount(tempTotalProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketStates.total]);
  /* -------------------------------------------------------------------------- */
  return (
    <div
      className={`rounded-md ${isSheet ? 'rounded-none' : ''} flex items-center justify-center ${isSticky ? ' ' : 'mb:4'}  admin-cost bg-[#eaf7ff] ${isSheet ? 'pl-1.5' : 'pl-3'} w-full xm:w-auto ${isCart ? 'flex-col pr-3 justify-start items-start py-3 rounded-none' : ''
        }`}
    >
      <div className={`flex items-start text-[#f60362] ${isCart ? 'text-base' : 'text-xs'} sm:text-base w-full ${!isCart ? 'hidden' : ''}`}>
        <span style={{ fontSize: 'inherit' }}>Contribute to admin costs:</span>
        <span className="ml-0.5 whitespace-nowrap">{ShowCurrencies2(currency, basketStates.adminCost || 0)}</span>
      </div>
      <div className={`w-full flex items-center justify-between ${!isSheet ? 'xm:justify-end' : ''} ${isCart ? '!justify-between flex-wrap' : ''}`}>
        <div className="flex items-center">
          <div className="relative group flex cursor-pointer" style={{ alignSelf: 'flex-start' }}>
            <span className="mr-1 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-5 h-5 ${isSheet ? 'mt-[3px]' : 'mt-1'} text-[#00a3da]`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>
            </span>
            <div
              className={`z-20 group-hover:flex hidden w-36 sm:w-64 absolute transition duration-150 ease-in-out
  md:top-4 md:-translate-y-1/2 md:-left-[265px]
  bottom-full left-0 mb-2 md:bottom-auto md:mb-0 shadow-lg bg-[#00a3da] text-[#00a3da] p-4 rounded`}
            >
              <svg
                className="absolute hidden md:block -right-[9px]  bottom-0 top-0 h-full"
                width="9px"
                height="16px"
                viewBox="0 0 9 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="currentColor">
                  <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="currentColor">
                    <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                      <g id="Group-2" transform="translate(24.000000, 0.000000)">
                        <polygon
                          id="Triangle"
                          transform="translate(4.500000, 62.000000) rotate(90.000000) translate(-4.500000, -62.000000) "
                          fill="currentColor"
                          points="4.5 57.5 12.5 66.5 -3.5 66.5"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span className="text-white text-xs md:text-[15px] leading-5 text-left">
                This contribution goes towards administrative costs and help us keep our 100% donation promise. Admin contributions are a form of
                donation in their own right as they help us to keep doing the work we do.
              </span>
            </div>
          </div>
          {/*<label className="hover:cursor-pointer text-[#f60362] text-xs text-right sm:text-base flex items-start flex-col sm:flex-row mr-1" htmlFor="checkboxDefault">*/}
          {/*{console.log("admin cost=", basketStates.adminCost)}*/}
          {/*<label className="hover:cursor-pointer text-[#f60362] text-xs text-right sm:text-base flex items-start flex-col sm:flex-row mr-1" htmlFor="checkboxDefault">*/}
          {/*{console.log("admin cost=", basketStates.adminCost)}*/}
          {/* <span className='font-medium text-left'>Contribute {basketStates.adminCost ? ShowCurrencies2(currency, basketStates.adminCost) : ShowCurrencies2(currency, 0)} towards admin costs </span> */}
          <label
            className={`hover:cursor-pointer text-[#f60362] ${isCart ? 'text-base' : 'text-xs'
              } text-right sm:text-base flex items-start flex-col sm:flex-row mr-1`}
            htmlFor="checkboxDefault"
          >
            <div className={`font-medium text-left flex flex-col ${isSheet ? 'sm:flex-col' : 'sm:flex-row'} items-start sm:items-center ${isSticky ? '' : 'gap-1'}`}>
              <div className={`flex items-center ${isCart ? 'hidden' : ''}`}>
                {isUsa ? (
                  <span style={{ fontSize: 'inherit' }}>Give a tip to cover our costs</span>
                ) : (
                  <>
                    <span style={{ fontSize: 'inherit' }}>Give admin contribution: </span>
                    <span className="ml-0.5 whitespace-nowrap">{ShowCurrencies2(currency, basketStates.adminCost || 0)}</span>
                  </>
                )}
              </div>
              {!isSheet && (
                <button
                  style={{ fontSize: 'inherit', whiteSpace: 'nowrap', textDecoration: 'underline' }}
                  onClick={() => onChange(0, true)}
                  disabled={!basketStates.adminCost}
                  className={`text-sm ${!basketStates.adminCost ? 'cursor-not-allowed opacity-50 text-gray-400' : 'cursor-pointer text-[#00A3DA]'}`}
                >
                  Remove your admin contribution
                </button>
              )}
              {/* <span>towards admin costs</span> */}
            </div>
            {!isSticky && !isSheet && selectedSubscribe !== 'one-off' && (
              <small className="ml-0 sm:ml-1">(uncheck this if subscribing by PayPal)</small>
            )}
          </label>
        </div>
        <div className=" text-white  items-center  shadow-[0px_0px_2px_rgb(220,220,220)] hover:shadow-[0px_0px_8px_rgb(216,216,216)] transition-all duration-200 flex justify-between p-1.5">
          <div
            className={` bg-[#00A3DA] flex mr-0.5 first-letter:flex justify-center items-center text-lg transition-all duration-200 h-[30px] w-5 ${basketStates.adminCost > 0 ? 'cursor-pointer hover:bg-[#f60362] hover:text-white' : 'cursor-not-allowed'
              }`}
            onClick={() => {
              if (basketStates.adminCost > 0) {
                setIsChanged(true);
                onChange(basketStates.adminCost - 1, true);
              }
            }}
          >
            -
          </div>
          <input
            ref={refQuantity}
            type="number"
            value={basketStates.adminCost}
            className={`center text-[#777777] max-w-[70%] w-12 md:w-14 text-lg border border-[#00A3DA] !rounded-none focus:outline-none focus:ring-0 focus:border-[#00A3DA] focus:border-1`}
            onChange={e => {
              const regex = /^(?:[1-9]\d*)?$/;
              if (regex.test(e.target.value)) {
                handleQty(e.target.value)
              }
            }}
            onKeyDown={e => handleKeyDown(e)}
            onBlur={e => handleOnBlurQty(e.target.value)}
          />

          <div
            className="text-white ml-0.5  flex bg-[#00A3DA] justify-center items-center text-lg transition-all duration-200 h-[30px] hover:bg-[#f60362] hover:text-white w-5  cursor-pointer"
            onClick={() => {
              setIsChanged(true);
              onChange(basketStates.adminCost + 1, true);
            }}
          >
            +
          </div>
          {/* {isSheet && (
            <div
            className="text-white ml-0.5  flex bg-[#f60362] justify-center items-center text-lg transition-all duration-200 h-[30px] hover:bg-[#f60362] hover:text-white w-5  cursor-pointer"
            onClick={() => onChange(0)}
            disabled={!basketStates.adminCost}
          >
            <IoCloseOutline className="text-lg" />
          </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AdminCosts;
