import React from 'react';
import ProductBox from '../../../components/general/productBox';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import SingleProductBox from '../../../components/general/singleProductBox';
import ProductCard from '../productCard';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import getSymbolFromCurrency from 'currency-symbol-map';
import IsGTMScriptLoaded from '../../../utils/isGTMScriptLoaded';
import { checkDomain } from '../../../utils/checkDomain';

const TabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    products = [],
    currency,
    showArrows = true,
    lang = 'en',
    isLoaded = false
  } = props;
  const productCount = products.length > 1 ? (products.length > 2 ? 2.1 : 2) : 1;
  const origin = productCount === 1 ? 'center' : 'auto';
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: productCount, spacing: 10, origin: origin },
        loop: productCount > 2,
      },
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 992px)': {
        slides: { perView: 3, spacing: 16, origin: 'center' },
      },
    },
    slides: {
      origin: 'center',
    },
  });
  /* -------------------------------------------------------------------------- */
  const handleIsAnyAmount = product => {
    const hasQuantity = product.quantity ? product.quantity > 1 : false;
    return Number(product.aud) <= 2 && !hasQuantity;
  };
  const ShowCurrencies = (currency, _price) => {
    const tempCurrency = currency?.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let final = '';
    price = price ? price.toLocaleString() : '0';
    switch (tempCurrency) {
      case 'GBP':
      case 'EUR':
        final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        final = `${price}`;
        break;
      case 'IDR':
        final = `Rp${price}`;
        break;
      case 'AED':
        final = `Dh${price}`;
        break;
      default:
        final = `$${price}`;
        break;
    }
    return final;
  };
  const handleViewItemDataLayer = (tempProduct, currency) => {
    const dataLayerTemp = {
      event: 'view_item',
      ecommerce: {
        currency: currency.toUpperCase(),
        items: [{
          item_id: tempProduct.creator,
          item_name: tempProduct.name,
          item_brand: tempProduct.category || '',
          item_category: tempProduct.category || '',
          item_description: tempProduct.description || '',
          price: tempProduct[currency.toLowerCase()]
        }]
      }
    };
    window.dataLayer = window.dataLayer || [];
    if (IsGTMScriptLoaded()) {
      window.dataLayer.push(dataLayerTemp);
    }
    const obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency,
      value: tempProduct[currency.toLowerCase()],
      price: tempProduct[currency.toLowerCase()],
      quantity: 1,
      content_category: tempProduct.category || 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description
    };
    if (window.ttq) {
      if (!(checkDomain('matwprojectme.org') || checkDomain('matwproject.org') || checkDomain('matwproject.org.uk'))) {
        window.ttq.instance(localStorage.getItem('TikTokID')).track('ViewContent', obj);
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4">
        {products.length > 0
          ? products.map((item, index) => (
            <div key={`products__${index}`} className="w-full basis-1/3 lg:basis-1/4 max-w-[300px]">
              {/* <ProductBox
                stateVariableObj={{ productName: item.name }}
                product={item}
                _lang={lang}
                linkBtnClasses={''}
                isStaticProduct={false}
                staticLink={''}
                staticLinkText={''}
                currency={currency ? currency : 'AUD'}
                titleClasses={`uppercase`}
                isPalestineRefugee={false}
              /> */}
              <ProductCard
                product={item}
                alt={''}
                _lang={'en'}
                removeDataLayer={false}
                isVisibleDescription={true}
                roundedButton={false}
                squareButton={false}
                selectedCurrencyStore={selectedCurrencyStore}
                handleViewItemDataLayer={handleViewItemDataLayer}
                handleIsAnyAmount={handleIsAnyAmount}
                ShowCurrencies={ShowCurrencies} />
            </div>
          ))
          : <span>No Products</span>
        }
      </div>
      <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
        {showArrows && products.length > 1 && (
          <>
            <div
              className="absolute translate-y-1/2 h-[24px] top-[47%] -left-[15px] z-10"
              onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
            >
              <LiaAngleLeftSolid color="white" size={30} />
            </div>
            <div
              className="absolute translate-y-1/2 h-[24px] top-[47%] -right-[15px] z-10"
              onClick={e => e.stopPropagation() || instanceRef.current?.next()}
            >
              <LiaAngleRightSolid color="white" size={30} />
            </div>
          </>
        )}
        <div ref={sliderRef} className="keen-slider">
          {products.length > 1 ? (
            products.map((item, index) => {
              return (
                <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center">
                  <div className="w-full max-w-[300px]">
                    {/* <ProductBox
                      roundedButton
                      stateVariableObj={{ productName: item.name }}
                      product={item}
                      _lang={lang}
                      linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                      isStaticProduct={item.isStaticProduct}
                      staticLink={item.staticLink}
                      staticLinkText={item.staticLinkText}
                      currency={currency ? currency : 'AUD'}
                      titleClasses={`uppercase`}
                      isPalestineRefugee={false}
                    /> */}
                    <ProductCard
                      product={item}
                      alt={''}
                      _lang={'en'}
                      removeDataLayer={false}
                      isVisibleDescription={true}
                      roundedButton={false}
                      squareButton={false}
                      selectedCurrencyStore={selectedCurrencyStore}
                      handleViewItemDataLayer={handleViewItemDataLayer}
                      handleIsAnyAmount={handleIsAnyAmount}
                      ShowCurrencies={ShowCurrencies} />
                  </div>
                </div>
              );
            })
          ) : (
            products[0] && (
              // <SingleProductBox
              //   stateVariableObj={{ productName: products[0].name }}
              //   product={products[0]}
              //   lang={lang}
              //   linkBtnClasses={''}
              //   isStaticProduct={false}
              //   staticLink={false}
              //   staticLinkText={false}
              //   currency={currency ? currency : 'AUD'}
              //   titleClasses={`uppercase`}
              //   isPalestineRefugee={false}
              // />
              <ProductCard
                product={products[0]}
                alt={''}
                _lang={'en'}
                removeDataLayer={false}
                isVisibleDescription={true}
                roundedButton={false}
                squareButton={false}
                selectedCurrencyStore={selectedCurrencyStore}
                handleViewItemDataLayer={handleViewItemDataLayer}
                handleIsAnyAmount={handleIsAnyAmount}
                ShowCurrencies={ShowCurrencies} />
            )
          )}
        </div>
      </div>
    </>
  );
};
export default TabContent;
